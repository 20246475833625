<template>
  <div>
    <!-- 业务类型 -->
    <div class="margin-top padding bg-white">
      <border-title titleName="类型信息" />
      <div class="margin-top flex aligin-items-center">
        <span>业务类型:</span>
        <div class="margin-left-50">
          <el-radio-group @change="refundTypeChange" v-model="refundType">
            <el-radio :label="1">学费退费</el-radio>
            <el-radio :label="2">余额退费</el-radio>
            <!-- <el-radio :label="3">教材杂费退费</el-radio> -->
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 学员信息 -->
    <div class="margin-top"><stu-info  @select="handleStudentSelected" :isCreatStudents="false" :isStuInfo="isStuInfo" :queryList="stuQueryList"/></div>
    <!-- 退费信息 -->
    <div class="margin-top padding bg-white">
      <border-title titleName="退费信息" />
      <div v-show="!isShow && refundType === 1">
        <div class="margin-top flex aligin-items-center">
          选择课程:
          <div class="margin-left-50">
            <el-select v-model="refundTuition.curriculumn_id" placeholder="请选择" size="mini" @change="stuCurriculumnChange">
              <el-option v-for="item in curriculumList" :key="item.id" :label="item.curriculum_name" :value="item.id" />
            </el-select>
          </div>
        </div>
        <div class="flex justify-content-between aligin-items-center">
          <div class="margin-top flex aligin-items-center">
            退费类型:
            <div class="margin-left-50">
              <el-radio-group v-model="refundTuition.refundtype">
                <el-radio :label="0">全部退费</el-radio>
                <el-radio :label="1">部分退费</el-radio>
              </el-radio-group>
              <el-tooltip class="item" effect="dark" content="转出部分剩余课程,课程仍然在读" placement="right">
                <i class="el-icon-question el-icon--right text-blue cursor" />
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="text-blue cursor">
            <i class="el-icon-zoom-in el-icon--right  cursor" />
            查看学费变动详情
          </div> -->
        </div>

        <div class="margin-top-sm ">
          <el-table border :data="orderList" style="width: 100%" >
            <el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
            <el-table-column prop="add_time" label="经办日期" align="center" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.add_time | dateTimeFilter('yyyy-MM-dd')}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="total_num" label="购买课时" align="center"></el-table-column>
            <el-table-column prop="valid_time" align="center" label="有效期" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.valid_time | dateTimeFilter('yyyy-MM-dd')}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="total_money" align="center" label="原价"></el-table-column>
            <el-table-column prop="use_discount_money" align="center" label="优惠"></el-table-column>
            <el-table-column prop="pay_money" align="center" label="实收"></el-table-column>
            <el-table-column prop="over_hours" align="center" label="剩余课时" width="140px"></el-table-column>
            <el-table-column prop="used_money" align="center" label="已用学费"></el-table-column>
            <el-table-column prop="refund_money" align="center" label="累计退出金额" width="120px"></el-table-column>
            <!-- <el-table-column prop="backprice" align="center" width="120px">
              <template slot="header" slot-scope="scope">
                <div class="flex aligin-items-center">
                  按原价退费
                  <el-tooltip class="item" effect="dark" content="转出部分剩余课程,课程仍然在读" placement="top">
                    <i class="el-icon-question el-icon--right text-blue cursor" />
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
               <as-switch active-text="原价" inactive-text="优惠" :height="20" :width="40"></as-switch>
              </template>
            </el-table-column> -->
            <el-table-column prop="refunc_class_hour" align="center" label="本次转出课时" width="150px">
              <template slot-scope="scope">
                <div class="flex aligin-items-center justify-content-center">
                  <el-input-number size="mini" @change="(item)=>hourChange(item,scope.$index)" :precision="2" style="width: 100px;" v-model="scope.row.refunc_class_hour" controls-position="right" :min="0" :max="scope.row.over_hours" />
                  <span class="el-icon--right">课时</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="refunc_money" align="center" label="本次转出金额" width="140px">
              <template slot-scope="scope">
                <el-input-number size="mini"  :precision="2" style="width: 120px;" v-model="scope.row.refunc_money" controls-position="right" @change="handleChange" :min="0" :max="scope.row.over_money"></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex justify-content-flexEnd margin-top padding-right text-grey">
          转结学费：<span class="text-bold text-lg text-black padding-lr-xs">{{refundTuition.order_price}}</span> 元
        </div>
      </div>
      
      <div v-if="!isShow && refundType === 2">
        <div class="margin-top text-bold">
          <span class="price-tag">￥</span>
          <span class="margin-left-sm">
            账户剩余：
            <span>{{overRecharge}}</span>
            元
          </span>
        </div>
        <div class="padding-tb-sm">
          退费金额：
          <el-input-number @change="refondChange" :precision="2" v-model="balanceTuition.money" controls-position="right" :min="0" :max="overRecharge" size="mini" />
          <span class="text-grey margin-left-xs">元</span>
        </div>
      </div>
      
      
      <div v-show="!isShow" class="margin-top tip padding-top flex aligin-items-center justify-content-between">
        <div class="flex aligin-items-center tip-item flex1">
          <div class="text-grey">对内备注</div>
          <div class="margin-left tip-item-input">
            <el-input v-model="refundTuition.order_in_remark" placeholder="该备注只有内部操作人员可见" size="mini" />
          </div>
        </div>
        <div class="flex aligin-items-center tip-item flex1">
          <div class="text-grey">对外备注</div>
          <div class="margin-left tip-item-input">
            <el-input v-model="refundTuition.order_outer_remak" placeholder="该备注将会被打印且会在校宝家中显示" size="mini" />
          </div>
        </div>
      </div>
      
      
      <div class="no-curriculums" v-show="isShow">
        <i class="el-icon-circle-plus-outline"></i>
        <p>请点击左上方按钮选择报名信息</p>
      </div>
    </div>

    <!-- 支付信息 -->
    <pay-info v-show="!isShow" :isRecharge="false" :isRefound="true" :totalPrice="refundTuition.order_price" @change="payChange" @schoolChange="schoolChange" />
    <!-- 订单标签 -->
    <order-tag @change="orderChange" />
    <!-- 经办信息 -->
    <handle-info :isSales="false" @change="handelChange" />

    <div class="sign-up-bottom padding">
      <div class="sign-up-bottom-btn"><el-button v-preventReClick="1000" @click="submite" type="primary" size="medium">保存</el-button></div>
    </div>
  </div>
</template>

<script>
import StuInfo from '../components/stuInfo.vue';
import HandleInfo from '../components/handleInfo.vue';
import OrderTag from '../components/orderTag.vue';
import PayInfo from '../components/payInfo.vue';
export default {
  components: {
    StuInfo,
    OrderTag,
    HandleInfo,
    PayInfo
  },
  data() {
    return {
      stuQueryList: {
        business_type: ['1'],
        has_balance: null, // 是否有余额
      },
      isShow: true,
      isStuInfo: false,
      refundType: 1,
      radio: 0,
      overRecharge: 0,
      options: [
        {
          value: '选项1',
          label: '交大二年级3-6人班'
        }
      ],
      value: '',
      priceValue: false,
      num: 0,
      curriculumList: [],
      orderList: [],
      refundTuition: {
        collections: [
          {
            pay_type: 0,
            money: 0, // 收支金额 ,
          }
        ],
        order_refunds: [
          // {
          //   // id: 0,
          //   order_id: 0,
          //   refund_type: 0, // 退费类型0=全部退费 1=部分退费 ,
          //   curriculum_id: 0, //  课程id ,
          //   student_id: 0,
          //   refunc_class_hour: 0, // 退费课时
          //   refunc_money: 0, //  退f费金额 ,
          //   order_curriculumn_id: 0, //  订单下订单课程记录id ,
          //   // add_time: '', // 添加时间 ,
          //   // add_user: 0 // 添加用户
          // }
        ],
        student_id: 0,
        curriculumn_id: '',
        refundtype: 0, // 退费类型0 = 全额退费 (全额退费), 1 = 部分退费 (部分退费)
        order_price: 0,
        order_in_remark: '',
        order_outer_remak: '',
        order_tags: '',
        sale_user: 0, // 销售员id
        handle_school: 0, // 经办校区id ,
        handle_date: '', // 经办日期
        handle_user: 0, // 经办人
      },
      balanceTuition: {
        collections: [
          {
            pay_type: 0,
            // opposite_account_number: '',
            // serial_number: '',
            money: 0,
          }
        ],
        student_id: 0,
        money: 0,
        order_price: 0,
        order_in_remark: '',
        order_outer_remak: '',
        order_tags: '',
        school_id: 0,
        handle_school: 0,
        handle_date: '',
        handle_user: 0,
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    //选择了学员
    handleStudentSelected(student) {
      if (student) {
        this.isShow = false;
        this.isStuInfo = true
        this.refundTuition.student_id = student.id;
        let obj = {
          PageIndex: 1,
          PageSize: 20,
          student_id: student.id
        }
        this.API.getCurriculumList(obj).then(res => {
          this.curriculumList = res.data.rows
        })
        this.API.GetBalance(student.id).then(res => {
          this.overRecharge = res.data.toFixed(2)*1
        })
      }
    },
    // 业务类型选择
    refundTypeChange(e) {
      this.refundTuition.order_price = 0
      this.isShow = true,
      this.isStuInfo = false
      if(e==2) {
        this.stuQueryList.business_type = ['2']
        this.stuQueryList.has_balance = true
      } else {
        this.stuQueryList.business_type = ['1']
        this.stuQueryList.has_balance = null
      }
    },
    // 转出课时
    hourChange(e, index) {
      this.refundTuition.order_price = 0
      this.orderList[index].refunc_money = e * this.orderList[index].price
      this.orderList.map(t => {
        this.refundTuition.order_price += t.refunc_money
      })
      this.refundTuition.order_price = this.refundTuition.order_price.toFixed(2)*1
    },
    // 选择课程
    stuCurriculumnChange(e) {
      this.API.GetOrderCurriculums(this.refundTuition.student_id, e).then(res => {
        res.data.map(t => {
          t.refunc_class_hour = t.over_hours
          t.refunc_money = t.over_money
          this.refundTuition.order_price += t.over_money
        })
        this.orderList = res.data
      })
    },
    handleChange(value) {
      console.log(value);
    },
    // 退费金额变化
    refondChange(e) {
      this.refundTuition.order_price = e
    },
    // 经办校区选择
    schoolChange(e) {
      this.refundTuition.school_id = e;
      this.refundTuition.handle_school = e;
    },
    // 支付信息
    payChange(e) {
      this.refundTuition.collections = e.payList;
    },
    // 订单标签选择
    orderChange(e) {
      this.refundTuition.order_tags = e.join(',');
    },
    // 日期选择
    handelChange(e) {
      this.refundTuition.handle_date = e.handle_date;
      this.refundTuition.handle_user = e.handle_user;
      this.refundTuition.sale_user = e.sale_user;
    },
    submite() {
      if(this.refundType === 1) {
        let obj = this.refundTuition
        this.orderList.map(t => {
          let arr = {
            refund_type: this.refundTuition.refundtype, // 退费类型0=全部退费 1=部分退费 ,
            curriculum_id: t.curriculum_id, //  课程id ,
            student_id: this.refundTuition.student_id,
            refunc_class_hour: t.refunc_class_hour, // 退费课时
            refunc_money: t.refunc_money, //  退f费金额 ,
            order_curriculumn_id: t.id, //  订单下订单课程记录id ,
          }
          obj.order_refunds.push(arr)
        })
        console.log(JSON.stringify(obj)) 
        this.API.RefundXueFei(obj).then(res => {
          if(res.code == 200) {
            this.$message.success(res.message)
            this.$router.go(-1)
          }else {
            this.$message.error(res.message)
          }
        })
      }else if(this.refundType === 2) {
        let obj = this.balanceTuition
        let arr = this.refundTuition
        obj.student_id = arr.student_id
        obj.handle_school = arr.handle_school
        obj.order_tags = arr.order_tags
        obj.handle_date = arr.handle_date
        obj.order_in_remark = arr.order_in_remark
        obj.order_outer_remak = arr.order_outer_remak
        obj.collections = arr.collections
        obj.order_price = obj.money
        console.log(obj);
        this.API.RefundYuE(obj).then(res => {
          if(res.code == 200) {
            this.$message.success(res.message)
            this.$router.go(-1)
          }else {
            this.$message.error(res.message)
          }
        })
      }else {
        
      }
    }
  }
};
</script>

<style scoped>
.tip {
  width: 100%;
}
.tip-item {
  width: 100%;
}
.tip-item-input {
  width: 85%;
}
.sign-up-bottom {
  bottom: 0;
  right: 0;
  position: fixed;
}
.no-curriculums {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
</style>
